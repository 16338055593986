import Vue from "vue";
import axios from "axios";

// axios请求地址
// axios.defaults.baseURL = 'http://localhost:3000/api/v1'
axios.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${window.sessionStorage.getItem(
    "token"
  )}`;
  return config;
});

axios.interceptors.response.use((response) => {
  // 在请求成功的情况下，直接返回响应数据
  // console.log(response)
  if (response.data.status === 1006) {
    router.push("/login");
  } else {
    return response;
  }
});

let host = window.location.host; // 主机
let reg = /^localhost+/;
if (reg.test(host)) {
  // 若本地项目调试使用
  axios.defaults.baseURL = "http://localhost:3003/api/v1";
} else {
  // 远程访问 使用代理
  axios.defaults.baseURL = "/api/v1";
}

Vue.prototype.$http = axios;
