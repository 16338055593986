<template>
    <div>
        <h3 class="title">分类列表</h3>
        <el-card>
            <el-row :gutter="20">
                <el-col :span="4">
                    <el-button type="primary" 
                    @click="addCatVisible = true"
                    >新增分类</el-button>
                </el-col>
            </el-row>
            
            <el-table 
            :data="Catlist"
            border
            style="width: 100%">
            <template  v-for="(item,index) in columns" >
                <el-table-column 
                :key="index"
                :prop="item.dataIndex"
                :label="item.title"
                width="150">
                </el-table-column>
            </template>
            <el-table-column  label="操作">
            <template  slot-scope="scope">
              <el-button type="success" @click="editCat(scope.row.ID)">编辑 <i class="el-icon-edit"></i></el-button>
              <el-button type="danger">删除 <i class="el-icon-remove-outline"></i></el-button>
            </template>
          </el-table-column>

            </el-table>

        <div class="block">
        <el-pagination 
          @size-change="handleSizeChange"        
          @current-change="handleCurrentChange"  
          :current-page="queryParam.pageNum"                
          :page-sizes="[2, 5, 10, 30, 50]"         
          :page-size="queryParam.pageSize"				 
          layout="total, sizes, prev, pager, next, jumper"
          :total="queryParam.total">				  
        </el-pagination>
      </div>

            <!-- <el-table 
            :columns="columns" 
            :pagination="pagination" 
            :dataSource="Catlist"
            bordered
           @change ="handleTableChange">
            <template slot="action" slot-scope="data">
                <div class="actionSlot">
                    <el-button type="primary" style="margin-right: 15px;" @click="editCat(data.ID)">编辑</el-button>
                    <el-button type="danger" @click = "deleteCat(data.ID)">删除</el-button>
                </div>
            </template>
            </el-table> -->

        </el-card>

        <!--  新增分类区域 -->
        <el-dialog
        closable
            title = "新增分类"
            :visible.sync ="addCatVisible"
            width="60%"
            destroyOnClose
        >
        <el-form :model="CatInfo" :rules="CatRules" ref="addCatRef">
            <el-form-item label="分类名称" prop ="Catname">
                <el-input v-model="CatInfo.Catname" ></el-input>
            </el-form-item>
        </el-form>
        <el-button @click="addCatCancel">取 消</el-button>
        <el-button type="primary" @click="addCatOk">确 定</el-button>
        </el-dialog>



        <!--  编辑分类区域 -->
        <el-dialog
        closable
            title = "编辑分类"
            :visible.sync ="editCatVisible"
            width="60%"
            destroyOnClose
        >
        <el-form :model="CatInfo" :rules="CatRules" ref="editCatRef">
            <el-form-item label="分类名称" prop ="CatInfo">
                <el-input v-model="CatInfo.name" ></el-input>
            </el-form-item>
        </el-form>
                <el-button @click="editCatCancel">取 消</el-button>
        <el-button type="primary" @click="editCatOk">确 定</el-button>
        </el-dialog>

    </div>
</template>

<script>
    const columns = [
        {
            title:"ID",
            dataIndex:"ID",
            width:"10%",
            key:'id',
            align:"center"
        },
        {
            title:"分类名称",
            dataIndex:"name",
            width:"20%",
            key:'Catname',
            align:"center"
        },
        // {
        //     title:"操作",
        //     width:"30%",
        //     key:'action',
        //     scopedSlots:{customRender:"action"},
        //     align:"center"
        // }
    ]
export default {
    data() {
        return {
            pagination:{
                pageSizeOptions:['2','5','10'],
                pageSize:5,
                total:0,
                showSizeChanger:true,
                showTotal:(total)=>`共${total}条`,


            },
            Catlist:[],
            columns,
            // queryParam:{
            //         pageSize:5,
            //         pageNum :1,
            //     },

        queryParam:{
        title: "",
        pageSize:10,
        pageNum :1,
        total:0,
      },
            CatInfo:{
                id:0,
                Catname:"",
            },
        //    CatInfo:[],
            visible:false,
            addCatVisible:false,
            editCatVisible:false,
            CatRules:{
                Catname:[
                    {
                        required:true,message:"请输入分类名",trigger:"blur"
                    },
                    {
                        min:1,max:12,message:"分类名称应该在1到12位之间",trigger:"blur"
                    }
                ]
            }
        }
    },



    created() {
        this.getCatList()
    },
    methods: {
       async getCatList(){
       const {data:res} = await this.$http.post("getCategoryList",{
                pagesize:this.queryParam.pageSize,
                pagenum:this.queryParam.pageNum
        })
        if(res.status != 200) return this.$message.error(res.message);
        this.Catlist =res.data
        // console.log(this.Catlist)
        this.pagination.total = res.total
       },

           handleSizeChange(val) { // 修改每页所存数据量的值所触发的函数
      this.queryParam.pageSize = val;  // 修改页的大小
      this.getCatList();       // 按新的pageNo和pageSize进行查询
    },
    
    handleCurrentChange(val) { // 修改当前页所触发的函数
      this.queryParam.pageNum = val;       // 更新当前的页
      this.getCatList();          // 按新的pageNo和pageSize进行查询
    },

       async searchCat(){
        this.queryParam.pageNum =1
        this.queryParam.current =1
       const {data:res} = await this.$http.post("getCategoryList",{
                pagesize:this.queryParam.pageSize,
                pagenum:this.queryParam.pageNum
        })
        if(res.status != 200) return this.$message.error(res.message);
        this.Catlist =res.data
        this.pagination.total = res.total
       },

       deleteCat(id){
        this.$confirm({
        title: '提示：请再次确认',
        content: '确定要删除该分类吗？一旦删除，无法恢复',
        onOk: async () => {
          const { data: res } = await this.$http.delete(`delCategory/${id}`)
          if (res.status != 200) return this.$message.error(res.message)
          this.$message.success('删除成功')
          this.getCatList()
        },
        onCancel: () => {
          this.$message.info('已取消删除')
        },
      })
       },


       addCatOk(){
        this.$refs.addCatRef.validate(
            async (valid)=>{
                if(!valid) return this.$message.error('参数不符合要求，请重新输入')
                const {data : res} = await this.$http.post(`addCategory`,
                {
                    name:this.CatInfo.Catname,
                })
                if (res.status != 200) {
                     this.$message.error(res.message)
                     return
                }
                this.$message({
                    message: '添加分类成功',
                    type: 'success'
                });
                this.addCatVisible = false
                this.$refs.addCatRef.resetFields()
                this.getCatList()
            })
       
       },


       addCatCancel(){
        this.$refs.addCatRef.resetFields()
        this.addCatVisible = false
       },
       adminChange(value){
        // console.log(value)
        this.CatInfo.role = value
        // this.CatInfo.role = parseInt(value)
       },

       async editCat(id){
        this.editCatVisible = true
        const { data: res } = await this.$http.post(`getCategory`,
        {"id":id})
        this.CatInfo = res.data
        this.CatInfo.id = id
       },

       editCatOk(){
        this.$refs.editCatRef.validate(async (valid) => {
        if (!valid) return this.$message.error('参数不符合要求，请重新输入')
        const { data: res } = await this.$http.put(`editCategory/${this.CatInfo.id}`, {
          name: this.CatInfo.name
        })
        if (res.status != 200) return this.$message.error(res.message)
        this.editCatVisible = false
        this.$message.success('更新分类信息成功')
        this.$refs.editCatRef.resetFields()
        this.getCatList()
      })
       },

       editCatCancel(){
        this.$refs.editCatRef.resetFields()
        this.editCatVisible = false
        this.$message.info("编辑取消")
       },

       handleTableChange(pagination,filters,sorter){
        var pager ={...this.pagination}
        pager.current = pagination.current
        pager.pageSize = pagination.pageSize
        this.queryParam.pageSize = pagination.pageSize
        this.queryParam.pageNum = pagination.current

        if(pagination.pageSize !== this.pagination.pageSize){
            this.queryParam.pageNum =1
            pager.current =1
        }

        this.pagination = pager
        this.getCatList()
    },

    },

}
</script>

<style scoped>
    .actionSlot{
       display: flex; 
       justify-content: center;
    }
        .title{
        text-align: center;
    }
</style>