<template>
  <div class="page-container">
    <!-- 第一部分：横跨全部，高度约为20% -->
    <div class="part-one">
      <el-row>
        <div class="title">汾湖高新区(黎里)分局 欢迎您</div>
      </el-row>
      <!-- <el-row>
        <div class="title2">欢迎您</div>
      </el-row> -->
    </div>

    <!-- 添加横线分割线 -->
    <!-- <hr class="separator"> -->

    <!-- 第二部分和第三部分各占据宽度的50% -->
    <div class="part-two">
      <!-- 第二部分：左边部分，占据宽度的50% -->
      <div class="left">
        <div class="row-v">
          <!-- 上部分，占20% -->
          <div class="v-t">车辆管理</div>

          <!-- 添加横线分割线 -->
          <!-- <hr class="separator"> -->
          <el-card class="card-v" style="border-radius: 40px">
            <!-- 下部分，占80% -->
            <div class="lower">
              <!-- 下面再划分为五列纵向排列 -->
              <div class="columns-1">
                <el-row class="horizontal-row">
                  <!-- 第一列内容 -->
                  <el-col class="v-name">
                    <div><br /></div>
                  </el-col>
                </el-row>
                <el-row class="horizontal-row">
                  <!-- 现况 -->
                  <el-col class="col-item"> 现况 </el-col>
                </el-row>
                <el-row class="horizontal-row">
                  <!-- 今日已预约 -->
                  <!-- <el-col class="col-item"> 今日预约 </el-col> -->

                  <div class="col-group">
                    <div class="col-item-p">预约人</div>
                    <div class="col-item-tt">
                      <el-col>
                        <el-row class="col-item-vp">09:00-11:00</el-row>
                        <el-row class="col-item-vp">13:00-15:00</el-row>
                        <el-row class="col-item-vp">15:00-17:00</el-row>
                      </el-col>
                    </div>
                  </div>
                </el-row>
              </div>
              <div class="columns-2">
                <el-row class="horizontal-row">
                  <el-col
                    class="v-name"
                    v-for="item in appointmentList"
                    :key="item.vehicle_name"
                  >
                    {{ item.vehicle_name }}
                  </el-col>
                </el-row>

                <!-- Second row: Vehicle Status -->
                <el-row class="horizontal-row">
                  <el-col
                    v-for="item in appointmentList"
                    :key="item.vehicle_name"
                    class="col-item"
                  >
                    {{ item.vehicle_status }}
                  </el-col>
                </el-row>

                <!-- Third row: Period -->
                <el-row class="horizontal-row">
                  <el-col
                    v-for="item in appointmentList"
                    :key="item.vehicle_name"
                    class="col-item"
                  >
                    <div v-if="item.period.length > 0">
                      <div class="vp" v-for="p in item.period" :key="p">
                        {{ p !== " " ? p : "&nbsp;" }}
                      </div>
                      <!-- <div v-for="p in item.period" :key="p">{{ formatPeriod(p) }}</div> -->
                    </div>
                    <!-- <ul v-if="item.period.length > 0">
                      <li v-for="p in item.period" :key="p">{{ p }}</li>
                    </ul> -->
                    <span v-else>无预约</span>
                  </el-col>
                </el-row>
              </div>
            </div>
          </el-card>
        </div>

        <div class="row-r">
          <!-- 在这里添加左边部分下半部分的内容 -->

          <!-- 上部分，占20% -->
          <div class="r-t">人员外出</div>

          <!-- 添加横线分割线 -->
          <!-- <hr class="separator"> -->
          <el-card class="card-r" style="border-radius: 40px">
            <!-- 下部分，占80% -->
            <div class="lower">
              <!-- 下面再划分为五列纵向排列 -->
              <div class="record-item">
                <el-row :key="index" class="record-item-row">
                  <el-col :span="8" class="col-item2">姓名</el-col>
                  <el-col :span="8" class="col-item2">事由</el-col>
                  <el-col :span="8" class="col-item2">备注</el-col>
                </el-row>

                <el-row
                  v-for="(item, index) in recordList"
                  :key="index"
                  class="record-item-row"
                >
                  <el-col :span="8" class="col-item">{{ item.name }}</el-col>
                  <el-col :span="8" class="col-item">{{ item.reason }}</el-col>
                  <el-col :span="8" class="col-item">{{ item.notes }}</el-col>
                </el-row>
              </div>
            </div>
          </el-card>
        </div>
      </div>

      <div class="right">
        <!-- 右边部分第一行 -->

        <div class="row-i">
          <div class="i-t">重要通知</div>
          <!-- <div class="new-h"> </div> -->
          <el-card
            class="card-i"
            style="border-radius: 40px; position: relative"
          >
            <!-- <div class="news" v-for="p in NewsList_1" :key="p">{{ p }}</div> -->
            <div class="news" v-for="(p, index) in NewsList_1" :key="index">
              <!-- <div class="news-index">{{ index + 1 }}</div> -->
              {{ index + 1 }}. {{ p }}
            </div>
            <div
              class="birthday"
              v-if="birthsList.length"
              style="
                position: absolute;
                bottom: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
              "
            >
              <!-- <div class="news-index">{{ index + 1 }}</div> -->
              <!-- {{ birthsList.join(", ") }} " 生日快乐" -->
              {{
                "今天是" +
                birthsList.join(", ") +
                "生日,感谢" +
                birthsList.join(", ") +
                " 对分局的辛勤付出，生日快乐！"
              }}
              <!-- 今天是**生日，感谢**对分局的辛勤付出，生日快乐！ -->
            </div>
          </el-card>
        </div>

        <div class="row-w">
          <div class="w-t">天气预报</div>
          <el-card class="card-w" style="border-radius: 40px; padding: 0px">
            <div class="weather-col">
              <el-col
                class="weather"
                v-for="(wi, index) in weatherList"
                :key="index"
              >
                <div class="weather-rows">
                  <el-row class="weather-row">
                    {{ wi.date }}
                  </el-row>
                  <el-row class="weather-row">
                    {{ wi.day }}
                  </el-row>
                  <el-row class="weather-row">
                    {{ wi.weather }}
                  </el-row>
                  <el-row class="weather-row">
                    {{ wi.min_temp }}- {{ wi.max_temp }}
                  </el-row>
                </div>
              </el-col>
            </div>
            <!-- <div class="weather">{{Weather.weather}}</div>
            <div class="weather">{{Weather.temperature}}</div>
            <div class="weather">{{Weather.humidity}}</div> -->
          </el-card>
        </div>

        <div class="row-e">
          <div class="e-t">应急情况</div>
          <el-card class="card-e" style="border-radius: 40px">
            <!-- <div class="news" v-for="p in NewsList_2" :key="p">{{ p }}</div> -->
            <div class="news" v-for="(p, index) in NewsList_2" :key="index">
              <!-- <div class="news-index">{{ index + 1 }}</div> -->
              {{ index + 1 }} {{ p }}
            </div>
          </el-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const columns = [
  // {
  //     title: "ID",
  //     dataIndex: "ID",
  //     width: "10%",
  //     key: 'id',
  //     align: "center"
  // },
  {
    title: "车辆名称",
    dataIndex: "vehicle_name",
    width: "10",
    key: "vehicle_name",
    align: "center",
  },
  {
    title: "车牌号",
    dataIndex: "vehicle_status",
    width: "10",
    key: "vehicle_status",
    align: "center",
  },

  {
    title: "时间",
    dataIndex: "period",
    width: "30",
    key: "period",
    align: "center",
  },

  // {
  //     title:"操作",
  //     width:"30%",
  //     key:'action',
  //     scopedSlots:{customRender:"action"},
  //     align:"center"
  // }
];
export default {
  data() {
    return {
      Weather: {
        weather: "",
        temperature: "",
        humidity: "",
      },
      weatherList: [],
      pagination: {
        pageSizeOptions: ["2", "5", "10"],
        pageSize: 5,
        total: 0,
        showSizeChanger: true,
        showTotal: (total) => `共${total}条`,
      },
      lpn_v: undefined,
      appointmentList: [],
      birthsList: [],
      recordList: [],
      vehicleList: [],
      vehicleNum: 0,
      columns,
      periodList: [],
      lpnList: [],
      NewsList: [],
      NewsList_1: [],
      NewsList_2: [],
      destList: ["莘塔", "金家坝", "芦墟", "北厍", "黎里"],
      reasonList: ["投诉举报处理", "特设设备检查", "食品安全检查"],
      // periodList: ["玉泉校区","紫金港校区","华家池校区","之江校区","西溪校区","海宁校区","校外"],
      disabledPeriods: [],
      disabledLPNs: [],

      // queryParam:{
      //         pageSize:5,
      //         pageNum :1,
      //     },

      queryParam: {
        title: "",
        pageSize: 10,
        pageNum: 1,
        total: 0,
      },
      appointmentInfo: {
        v_name: "",
        lpn: "",
        time: "",
        dest: "",
        period: "",
        reason: "",
        driver: "",
      },
      vehicleInfo: {
        v_name: "",
        lpn: "",
      },
      //    AppointmentInfo:[],
      visible: false,
      addAppointmentVisible: false,
      editAppointmentVisible: false,
      appointmentRules: {
        period: [
          {
            required: true,
            message: "请选择时间段",
            trigger: "blur",
          },
        ],
        lpn: [
          {
            required: true,
            message: "请选择车辆",
            trigger: "blur",
          },
        ],
        dest: [
          {
            required: true,
            message: "请选择目的地",
            trigger: "blur",
          },
        ],
        reason: [
          {
            required: true,
            message: "请选择事由",
            trigger: "blur",
          },
        ],
        driver: [
          {
            required: true,
            message: "请填入驾驶员",
            trigger: "blur",
          },
        ],
      },
    };
  },

  created() {
    this.getAllUser();
    this.getAppointmentToday();
    this.getRecordToday();
    this.getNewsToday();
    this.getWeather();
  },
  mounted() {},
  methods: {
    async getAllUser() {
      const { data: res } = await this.$http.post("getAllUser", {});
      if (res.status != 200) return this.$message.error(res.message);
      const currentDate = new Date();
      const currentMonth = currentDate.getMonth() + 1; // 月份从0开始，所以需要加1
      const currentDay = currentDate.getDate();
      const b1 = res.data.filter((item) => {
        // 解析生日字符串为日期对象
        const birthdayDate = new Date(item.birthday);
        const birthdayMonth = birthdayDate.getMonth() + 1; // 月份从0开始，所以需要加1
        const birthdayDay = birthdayDate.getDate();
        // 检查生日是否与今天相同
        return birthdayMonth === currentMonth && birthdayDay === currentDay;
      });

      this.birthsList = b1.map((item) => item.name);

      // console.log(this.birthsList);

      // console.log("this.birthsList");
      // console.log(this.birthsList);
      // console.log("this.birthsList");

      // this.Userlist = res.data;
      // this.queryParam.total = res.total;
      // this.UserMap = res.data.reduce((user_map, item) => {
      //   user_map[item.ID] = item.name;
      //   return user_map;
      // }, {});
      // console.log(this.UserMap);
      // console.log(this.Userlist)
      // this.pagination.total = res.total
    },
    async getWeather() {
      const { data: res } = await this.$http.post("getWeather", {});
      if (res.status != 200) return this.$message.error(res.message);
      // this.Weather = res.data
      this.weatherList = res.data;
    },
    formatPeriod(period) {
      const parts = period.split(" "); // 通过空格分割字符串为数组
      return parts[1]; // 取数组的第二部分（空格后面部分）
    },

    async getAppointmentToday() {
      const { data: res } = await this.$http.post("getAppointmentToday", {});
      if (res.status != 200) return this.$message.error(res.message);
      this.appointmentList = Object.values(res.data);
    },

    async getRecordToday() {
      const { data: res } = await this.$http.post("getRecordToday", {});
      if (res.status != 200) return this.$message.error(res.message);
      this.recordList = res.data;
    },
    async getNewsToday() {
      const { data: res } = await this.$http.post("getNewsToday", {});
      if (res.status != 200) return this.$message.error(res.message);
      this.NewsList = res.data;
      const f_1 = this.NewsList.filter((item) => item.type === "重要事项");
      const f_2 = this.NewsList.filter((item) => item.type === "应急情况");
      this.NewsList_1 = f_1.map((item) => item.name);
      this.NewsList_2 = f_2.map((item) => item.name);
    },
  },
};
</script>

<style>
.col-group {
  /* width: 350rpx; */
  /* flex: 1; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.col-item-p {
  /* flex: 1; */
  /* 设置flex属性为1，让列均匀分布 */
  display: flex;
  justify-content: center;
  font-size: 16px;
  writing-mode: vertical-rl;
  /* align-items: center; */
}

.col-item-tt {
  /* flex: 1; */
  width: 200rpx;
  /* 设置flex属性为1，让列均匀分布 */
  display: flex;
  justify-content: left;
  font-size: 16px;
  /* align-items: center; */
}

.col-item-vp {
  flex: 1;
  /* 设置flex属性为1，让列均匀分布 */
  display: flex;
  justify-content: center;
  font-size: 16px;
  white-space: nowrap; /* 不换行 */
  /* align-items: center; */
}

/* .vp {
  display: flex;
  font-size: 14px;
} */

/* 样式可以根据您的需求进行调整 */
.page-containerbak {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  /* background-color: #013788; */
  /* background-color: #044c94; */
  /* 深蓝色背景 */
  /* color: white; */
  /* color: white; */
  /* 白色字体颜色 */
}

.page-container {
  width: 100%;
  height: 100vh;
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  /* background-color: #013788; */

  background-image: url("/static/1.png");
  /* background-color: transparent !important;  */
  /* 替换为您的背景图片路径 */
  background-size: cover;
  /* 设置背景图片的大小，可以根据需要使用不同的值 */
  background-repeat: no-repeat;
  /* 禁止背景图片重复 */
  background-position: center;
  /* 将背景图片居中显示 */
  /* background-color: #044c94; */
  /* 深蓝色背景 */
  /* color: white; */
  /* color: white; */
  /* 白色字体颜色 */
}

.part-one {
  /* 第一部分样式 */
  width: 100%;
  height: 15%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "Noto Serif SC", serif;
  /* 使用公文的字体 */
  font-size: 56px;
  /* 设置字体大小 */
  font-weight: bold;
  /* 字体加粗 */
  /* background-color: hsl(32, 91%, 46%) !important; */

  color: white !important;
  /* background-color: rgb(1, 106, 161) !important; */
}

.part-two {
  /* 第二部分和第三部分样式 */
  display: flex;
  height: 80%;
  /* flex: 1; */
}

.left {
  /* 左边部分样式 */
  width: 50%;
  display: flex;
  flex-direction: column;
}

.right {
  /* 右边部分样式 */
  width: 50%;
  display: flex;
  flex-direction: column;
}

/*车辆预约*/
.row-v {
  height: 50%;
  /* flex: 1 */
}

/*外出记录*/
.row-r {
  height: 50%;
  /* flex: 1 */
}

/*重要事项*/
.row-i {
  height: 50%;
  /* flex: 1 */
}

/*危急情况*/
.row-e {
  height: 18%;
  /* flex: 1; */
}

/*天气预报*/
.row-w {
  height: 32%;
  /* flex: 1 */
}

.vp {
  font-size: 16px;
}

/*车辆预约的标题样式*/
.v-t {
  /* 上部分样式，占20% */
  height: 20%;
  display: flex;
  align-items: center;
  color: #ffffff !important;
  justify-content: center;
  /* font-family: 'Noto Serif SC', serif; */
  font-family: "黑体", "Heiti SC", "微软雅黑", Arial, sans-serif; /* 设置字体为黑体，包括备用字体 */
  /* 使用公文的字体 */
  font-size: 24px;
  /* 设置字体大小 */
  font-weight: bold;
  /* 字体加粗 */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  /* 阴影效果 */
  opacity: 0.9;
}

/*外出记录的标题样式*/
.r-t {
  /* 上部分样式，占20% */
  height: 20%;
  margin-top: 10px;
  color: #ffffff !important;
  display: flex;
  align-items: center;
  justify-content: center;
  /* font-family: 'Noto Serif SC', serif; */
  font-family: "黑体", "Heiti SC", "微软雅黑", Arial, sans-serif; /* 设置字体为黑体，包括备用字体 */
  /* 使用公文的字体 */
  font-size: 24px;
  /* 设置字体大小 */
  font-weight: bold;
  /* 字体加粗 */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  /* 阴影效果 */
  opacity: 0.9;
}

/*重要事项样式*/
.i-t {
  /* 上部分样式，占20% */
  height: 20%;
  display: flex;
  align-items: center;
  color: #ffffff !important;
  justify-content: center;
  /* font-family: 'Noto Serif SC', serif; */
  font-family: "黑体", "Heiti SC", "微软雅黑", Arial, sans-serif; /* 设置字体为黑体，包括备用字体 */
  /* 使用公文的字体 */
  font-size: 24px;
  /* 设置字体大小 */
  font-weight: bold;
  /* 字体加粗 */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  /* 阴影效果 */
  opacity: 0.9;
}

/*重要事项样式*/
.e-t {
  /* 上部分样式，占20% */
  height: 40%;
  margin-top: 10px;
  display: flex;
  align-items: center;
  color: #ffffff !important;
  justify-content: center;
  /* font-family: 'Noto Serif SC', serif; */
  font-family: "黑体", "Heiti SC", "微软雅黑", Arial, sans-serif; /* 设置字体为黑体，包括备用字体 */
  /* 使用公文的字体 */
  font-size: 24px;
  /* 设置字体大小 */
  font-weight: bold;
  /* 字体加粗 */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  /* 阴影效果 */
  opacity: 0.9;
}

/*重要事项样式*/
.w-t {
  /* 上部分样式，占20% */
  height: 35%;
  margin-top: 10px;
  /* margin-bottom: 10px; */
  display: flex;
  align-items: center;
  color: #ffffff !important;
  justify-content: center;
  /* font-family: 'Noto Serif SC', serif; */
  /* 使用公文的字体 */
  font-size: 24px;
  /* 设置字体大小 */
  font-family: "黑体", "Heiti SC", "微软雅黑", Arial, sans-serif; /* 设置字体为黑体，包括备用字体 */
  font-weight: bold;
  /* 字体加粗 */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  /* 阴影效果 */
  opacity: 0.9;
}

.card-v {
  border-color: rgb(75, 123, 208) !important;
  height: 80%;

  color: #ffffff !important;
  /* background: transparent !important; */

  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5) !important;
  /* 添加虚化效果的阴影 */
  /* border: 1px solid transparent !important; */
  background-color: rgba(
    110,
    166,
    230,
    0.7
  ) !important; /* 使用 rgba 来设置带透明度的背景颜色 */
  /* background-color: rgba(71, 149, 237, 0.7) !important; 使用 rgba 来设置带透明度的背景颜色 */

  /* border-radius: 20px; */
  /* border: 2px solid rgb(75, 123, 208)!important; 增加边框宽度 */
  border-radius: 50px; /* 设置大圆角，将20px替换为您想要的圆角半径值 */
  /* 卡片圆角 */
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); */
  border: 1px solid transparent !important;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0) !important;
  /* 卡片立体感阴影效果 */
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  /* 外边距，留出空隙 */
  padding: 0px;
}

.card-r {
  height: 80%;
  border: 1px solid #ccc;
  /* 卡片边框样式 */

  border-radius: 10px;
  /* 卡片圆角 */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  /* 卡片立体感阴影效果 */
  /* margin: 10px; */
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  /* 外边距，留出空隙 */
  padding: 0px;
  /* 卡片内边距 */
  /* background-color: rgba(75, 123, 208, 0.7) !important; 使用 rgba 来设置带透明度的背景颜色 */
  background-color: rgba(
    110,
    166,
    230,
    0.7
  ) !important; /* 使用 rgba 来设置带透明度的背景颜色 */
  /* background: transparent !important; */

  color: #ffffff !important;
  /* background-color:#d5e0cf !important; */
  /* background-color: rgb(16, 159, 211) !important; */
  /* background-color: linear-gradient(to right, #ff9900, #ff3366) !important; */

  border: 1px solid transparent !important;
  /* 设置透明的边框 */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0) !important;
  /* 添加虚化效果的阴影 */
  /* 设置卡片背景颜色 */
  /* 或者 background: rgba(0, 0, 0, 0); */
}

.record-item-row {
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
}

.card-i {
  border-color: #0f58eb;
  height: 80%;

  color: #ffffff !important;
  /* background: transparent !important; */
  /* background-color: rgba(75, 123, 208, 0.7) !important; 使用 rgba 来设置带透明度的背景颜色 */
  background-color: rgba(
    110,
    166,
    230,
    0.7
  ) !important; /* 使用 rgba 来设置带透明度的背景颜色 */

  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5) !important;
  /* 添加虚化效果的阴影 */
  /* border: 1px solid transparent !important; */
  border: 1px solid transparent !important;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0) !important;

  border-radius: 10px;
  /* 卡片圆角 */
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); */
  /* 卡片立体感阴影效果 */
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  /* 外边距，留出空隙 */
  padding: 0px;
}

.card-e {
  border-color: #0f58eb;
  height: 65%;

  color: #ffffff !important;
  /* background: transparent !important; */
  /* background-color: rgba(75, 123, 208, 0.7) !important; 使用 rgba 来设置带透明度的背景颜色 */
  background-color: rgba(
    110,
    166,
    230,
    0.7
  ) !important; /* 使用 rgba 来设置带透明度的背景颜色 */

  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5) !important;
  /* 添加虚化效果的阴影 */
  /* border: 1px solid transparent !important; */
  border: 1px solid transparent !important;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0) !important;

  border-radius: 10px;
  /* 卡片圆角 */
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); */
  /* 卡片立体感阴影效果 */
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  /* 外边距，留出空隙 */
  padding: 0px;
}

.card-w {
  border-color: #0f58eb;
  height: 65%;
  /* width: 100%; */

  color: #ffffff !important;
  background: transparent !important;
  /* background-color: transparent !important;  */
  /* background-color: rgb(75, 123, 208)!important; */
  /* background-color: rgba(75, 123, 208, 0.7) !important; 使用 rgba 来设置带透明度的背景颜色 */
  background-color: rgba(
    110,
    166,
    230,
    0.7
  ) !important; /* 使用 rgba 来设置带透明度的背景颜色 */

  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5) !important;
  /* 添加虚化效果的阴影 */
  border: 1px solid transparent !important;
  /* border: 1px solid transparent !important; */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0) !important;

  border-radius: 10px;
  /* 卡片圆角 */
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); */
  /* 卡片立体感阴影效果 */
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  /* 外边距，留出空隙 */
  padding: 0px;
}

.title {
  /* display: flex;
    align-items: center;
    justify-content: center; */
  margin-bottom: 2px; /* 调整标题之间的垂直间距，可以根据需要调整数值 */
  /* font-family: 'Noto Serif SC', serif; */
  /* font-family: 'Noto Serif SC', serif; */
  font-family: "黑体", "Heiti SC", "微软雅黑", Arial, sans-serif; /* 设置字体为黑体，包括备用字体 */
  /* 使用公文的字体 */
  font-size: 38px;
  font-weight: bolder;

  /* 设置字体大小 */
  padding-top: 15px;
}

.title2 {
  /* display: flex;
    align-items: center;
    justify-content: center; */
  margin-bottom: 2px; /* 调整标题之间的垂直间距，可以根据需要调整数值 */
  /* font-family: 'Noto Serif SC', serif; */
  /* font-family: 'Noto Serif SC', serif; */
  font-family: "黑体", "Heiti SC", "微软雅黑", Arial, sans-serif; /* 设置字体为黑体，包括备用字体 */
  /* 使用公文的字体 */
  font-size: 28px;
  font-weight: bolder;

  /* 设置字体大小 */
  padding-top: 15px;
}

.new-h {
  height: 15px;
}

.news {
  display: flex;
  /* align-items: center; */
  align-items: left;
  /* justify-content: center; */
  font-family: "Noto Serif SC", serif;
  /* 使用公文的字体 */
  font-size: 22px;
  /* color: black; */
  /* 设置字体大小 */
  margin-top: 5px;
}

.birthday {
  display: flex;
  align-items: center !important;
  /* align-items: left; */
  justify-content: center !important;
  font-family: "Noto Serif SC", serif;
  /* 使用公文的字体 */
  font-size: 22px;
  /* color: black; */
  /* 设置字体大小 */
  margin-top: 15px;
}

.weather-col {
  flex: 1;
  display: flex;
  /* width: 100%; */
  justify-content: space-between;
  flex-direction: row;
  /* padding: 0px; */
  margin-top: 0px;
}

.weather-row {
  display: flex;
  align-items: center;
  margin-bottom: 3px;
  justify-content: center;
}

/* .weather-rows{
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  } */

.weather {
  /* display: flex; */
  /* flex-direction: row; */
  /* align-items: center; 
    justify-content: center; */

  font-family: "Noto Serif SC", serif;
  /* 使用公文的字体 */
  font-size: 15px;
}

/* .weather {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Noto Serif SC', serif;
    font-size: 22px;
  } */

.lower {
  /* 下部分样式，占80% */
  height: 80%;
  display: flex;
  flex-direction: row;
}

/* .columns-2 {
    flex: 1;
    display: flex;
    width: 100%;
    flex-direction: column;
  } */

.columns-2 {
  /* 下面再划分为横向排列的五列，每一列的样式 */
  /* width: 84%; */
  flex: 1;
  display: flex;
  width: 100%;
  flex-direction: column;
  /* justify-content: center; 居中内容 */
  /* align-items: center; 居中内容 */
  /* flex-direction: column; */
  /* border: 1px solid white;  添加边框 */
  /* margin: 10px; 添加间距 */
}

.v-name {
  flex: 1;
  /* 设置flex属性为1，让列均匀分布 */
  display: flex;
  justify-content: center;
  font-size: 18px;
  /* 设置字体大小 */
  font-weight: bold;
  /* 字体加粗 */
}

/* .el-row {
  display: flex;
  flex-direction: row;
  margin: 15px;

} */

.horizontal-row {
  display: flex;
  flex-direction: row;
  margin: 5px;
  /* flex: 1; */
}

.horizontal-row-1 {
  display: flex;
  flex-direction: row;
  margin: 15px;
  /* flex: 1; */
}

.col-item {
  flex: 1;
  /* 设置flex属性为1，让列均匀分布 */
  display: flex;
  justify-content: center;
  /* align-items: center; */
}

.col-item2 {
  flex: 1;
  /* 设置flex属性为1，让列均匀分布 */
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
  font-size: 18px;
  font-weight: bold; /* 可选：加粗数字 */
  /* align-items: center; */
}

.col-item-2 {
  flex: 1;
  /* 设置flex属性为1，让列均匀分布 */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .horizontal-row {
  display: flex;

  } */

.columns {
  /* 下面再划分为横向排列的五列，每一列的样式 */

  flex: 1;
  display: flex;
  justify-content: center;
  /* 居中内容 */
  align-items: center;
  /* 居中内容 */
  /* border: 1px solid white;  添加边框 */
  margin: 5px;
  /* 添加间距 */
}

.record-item {
  /* 下面再划分为横向排列的五列，每一列的样式 */
  flex: 1;
  /* display: flex; */
  width: 100%;
  /* flex-direction: column; */
  margin: 5px;
}

.columns-1 {
  /* flex: 1; */
  display: flex;
  width: 18%;
  flex-direction: column;

  /* flex: 1;  */
  /* display: flex;
    width: 16%; */
  /* height: 100%; */
  /* flex-direction: column; */
  /* align-items: center;
    justify-content: center; */

  /* 其他样式，根据您的需求进行调整 */
  /* width: 16%; */
  /* flex: 1;  */
  /* display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center; */
  /* 让文本水平居中 */
  /* height: 100%; */
  /* font-family: 'Noto Serif SC', serif;
    font-size: 18px;  */
  /* 让div撑满父容器的高度，以便实现上下居中 */
}

/* .separator {
    border: none;
    border-top: 1px solid white;
    margin: 0px 0;
  } */

.separator {
  /* 横线分割线样式 */
  border: none;
  border-top: 2px solid rgb(95, 139, 130);
  /* 使用虚线并设置颜色 */
  /* border-top: 2px dashed #31528a;  */
  /* border-top: 2px dashed #1867f0;  */
  /* border-top: 2px dashed #d6d1d1;  */
  margin: 0px 0;
  /* 添加一些垂直间距，使其更具空间感 */
}

/* .vertical-line {
    border-left: 1px solid white;
    height: 100%;
    margin: 0px;
  } */

.vertical-line {
  /* 竖线分割线样式 */
  border: none;
  border-left: 2px solid rgb(95, 139, 130);
  /* 使用虚线并设置颜色 */
  height: 100%;
  margin: 0px 0;
  /* 添加一些垂直间距，使其更具空间感 */
}

.news-index {
  display: inline-block; /* 将数字显示为内联块元素，使其在文本之前显示 */
  /* width: 20px; 调整数字框的宽度，根据需要调整 */
  text-align: left; /* 左对齐数字 */
  margin-right: 10px; /* 调整数字与文本之间的间距，根据需要调整 */
  font-weight: bold; /* 可选：加粗数字 */
}

.space {
  height: 20px;
}
</style>
