import Vue from 'vue'
import './plugin/axios'
import App from './App.vue'
import router from './router'
// import Highlight from "./utils/mhighlight";
import 'element-ui/lib/theme-chalk/index.css'
import "viewerjs/dist/viewer.css"; 
import Viewer from "v-viewer";
import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'
 


import { Button, Input, Pagination, Table, TableColumn, MessageBox,
  Container,Header,Main,Menu,MenuItem,Avatar,Tag,Aside,
  Card,Form,FormItem,Select,Upload,Icon,Row,Dialog,Col,
  Message,Option,DatePicker,RadioGroup,Radio,Submenu,Image,ButtonGroup} from 'element-ui';

import moment from 'moment'
Vue.filter('dateFormat', function (dateStr,pattern = "YYYY-MM-DD HH:mm:ss") {
  return moment(dateStr).format(pattern);
})
 

Vue.prototype.$message = Message;

Vue.filter('dateFormat', function (dateStr,pattern = "YYYY-MM-DD HH:mm") {
  return moment(dateStr).format(pattern);
})

// Vue.use(ElementUI)
Vue.use(Button)
Vue.use(Input)
Vue.use(Pagination)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(MessageBox)
Vue.use(Container)
Vue.use(Header)
Vue.use(Main)
Vue.use(Menu)
Vue.use(Aside)
Vue.use(MenuItem)
Vue.use(Avatar)
Vue.use(Tag)
Vue.use(Card)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Select)
Vue.use(Upload)
Vue.use(Icon)
Vue.use(Row)
Vue.use(Dialog)
Vue.use(Col)
Vue.use(Option)
Vue.use(DatePicker)
Vue.use(RadioGroup)
Vue.use(Radio)
Vue.use(Submenu)
Vue.use(Image)
Vue.use(ButtonGroup)




// Vue.use(Highlight)
Vue.use(mavonEditor)
Vue.use(Viewer);

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
