<template>
    <div>
        <h3 class="title">Activity列表</h3>

        <el-card>
            <!-- <el-row :gutter="20"> -->

                <!-- <el-col :span="6">
                    <el-input-search v-model="queryParam.title" 
                    placeholder="输入文章名查找" 
                    enter-button 
                    allowClear
                    @search="searchActivity"></el-input-search>
                </el-col> -->

                <!-- <el-col :span="6">
                    <el-input type="text" prefix-icon="el-icon-search" v-model="queryParam.title" placeholder="输入文章名查找"
                        style="width: 270px; cursor: pointer" @keyup.enter.native="searchActivity"></el-input>
                </el-col>

                <el-col :span="4">
                    <el-button type="primary" @click="$router.push(`/AddActivity`)">新增</el-button>
                </el-col> -->


                <!-- <el-col :span="6" :offset="4">
                    <el-select v-model="activityInfo.cid" placeholder="请选择分类" style="width: 200px;" @change="CatChange">
                        <el-option v-for="item in Catlist" :key="item.ID" :value="item.ID" :label="item.name">
                            {{item.name}}
                        </el-option>
                    </el-select>

                </el-col>
            </el-row> -->

            <!--  讲座列表区域 -->
            <el-table :data="Activitylist" border style="width: 100%">
                <template v-for="(item,index) in columns">
                    <el-table-column :key="index" :prop="item.dataIndex" :label="item.title" width="150">
                    </el-table-column>
                </template>

                <el-table-column label="开始时间">
                    <template slot-scope="scope2">
                        <span>{{scope2.row.begin_time | dateFormat}}</span>
                    </template>
                </el-table-column>

                 <el-table-column label="结束时间">
                    <template slot-scope="scope2">
                        <span>{{scope2.row.end_time | dateFormat}}</span>
                    </template>
                </el-table-column>

                <el-table-column label="地点">
                    <template slot-scope="scope2">
                        <span>{{scope2.row.addr}}</span>
                    </template>
                </el-table-column>

                                <!-- <el-table-column label="分类">
                    <template slot-scope="scope2">
                        <span>{{scope2.row.Category.name}}</span>
                    </template>
                </el-table-column> -->

                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <!-- <el-button type="success" @click="editPush(scope.row.ID,scope.index)">编辑 <i class="el-icon-edit"></i></el-button> -->
                        <el-button type="success" @click="enrollPush(scope.row.ID)">报名 <i
                                class="el-icon-edit"></i></el-button>

                        <!-- <el-button type="danger">删除 <i class="el-icon-remove-outline"></i></el-button> -->
                    </template>
                </el-table-column>
            </el-table>

            <!-- <div class="block">
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    :current-page="queryParam.pageNum" :page-sizes="[2, 5, 10, 30, 50]" :page-size="queryParam.pageSize"
                    layout="total, sizes, prev, pager, next, jumper" :total="queryParam.total">
                </el-pagination>
            </div> -->

            <div class="block">
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    :current-page="queryParam.pageNum" :page-sizes="[2, 5, 10, 30, 50]" :page-size="queryParam.pageSize"
                    layout="sizes, prev, pager, next" :total="queryParam.total">
                </el-pagination>
            </div>
        </el-card>


    </div>
</template>

<script>

    const columns = [
        // {
        //     title: "ID",
        //     dataIndex: "ID",
        //     width: "10%",
        //     key: 'id',
        //     align: "center"
        // },
        {
            title: "Activity标题",
            dataIndex: "name",
            width: "20%",
            key: 'name',
            align: "center"
        }
    ]
    export default {
        props: ['id'],
        data() {
            return {
                pagination: {
                    pageSizeOptions: ['2', '5', '10'],
                    pageSize: 5,
                    total: 0,
                    showSizeChanger: true,
                    showTotal: (total) => `共${total}条`,
                },
                Activitylist: [],
                Catlist: [],
                Catlists: [],
                columns,
                queryParam: {
                    title: "",
                    pageSize: 10,
                    pageNum: 1,
                    total: 0,
                },
                activityInfo: {
                    id: 0,
                    name: '',
                    cid: undefined,
                    begin_time: undefined,
                    end_time: undefined,
                    content: '',
                    num: 0,
                    image: '',
                },
                visible: false,
                addActivityVisible: false,
                editActivityVisible: false,
                ActivityRules: {
                    name: [
                        {
                            required: true, message: "请输入Activity标题", trigger: "blur"
                        },
                        {
                            min: 1, max: 36, message: "Activity标题应该在1到30位之间", trigger: "blur"
                        }
                    ]
                }
            }
        },



        created() {
            this.getActivityList()
            this.getCatList()

        },
        mounted() {
            if (this.id) {
                this.getActivityInfo(this.id)
            }
        },
        methods: {

            // editPush(id) {
            //     this.$router.push({ path: `/AddActivity/${id}` })
            // },

            enrollPush(id) {
                this.$router.push({ path: `/AddEnroll/${id}` })
            },

            async getActivityInfo(id) {
                const { data: res } = await this.$http.post("getActivity", {
                    id: parseInt(id)
                })
                if (res.status != 200) return this.$message.error(res.message);
                this.activityInfo = res.data
                this.activityInfo.id = res.data.ID
            },

 
            handleSizeChange(val) { // 修改每页所存数据量的值所触发的函数
                this.queryParam.pageSize = val;  // 修改页的大小
                this.getActivityList();       // 按新的pageNo和pageSize进行查询
            },

            handleCurrentChange(val) { // 修改当前页所触发的函数
                this.queryParam.pageNum = val;       // 更新当前的页
                this.getActivityList();          // 按新的pageNo和pageSize进行查询
            },

            async editActivity(id) {
                this.editActivityVisible = true
                const { data: res } = await this.$http.post(`getActivity`,
                    { "id": id })
                this.ActivityInfo = res.data
                this.Activity.id = id
            },


            async getCatList() {
                const { data: res } = await this.$http.post("getCategoryAll")
                if (res.status != 200) return this.$message.error(res.message);
                this.Catlist = res.data;
                this.Catlists = res.data
            },

            CatChange(cid) {
                // console.log("idddd")
                // console.log(cid)
                this.getActivityListByCat(cid)
            },

            async getActivityListByCat(id) {
                const { data: res } = await this.$http.post("getActivityByCategory", {
                    id: id,
                    pagesize: this.queryParam.pageSize,
                    pagenum: this.queryParam.pageNum
                })
                if (res.status != 200) return this.$message.error(res.message);
                this.Activitylist = res.data
                this.queryParam.total = res.total
            },

            async getActivityList() {
                const { data: res } = await this.$http.post("getActivityList", {
                    title: this.queryParam.title,
                    pagesize: this.queryParam.pageSize,
                    pagenum: this.queryParam.pageNum
                })
                if (res.status != 200) return this.$message.error(res.message);
                this.Activitylist = res.data
                this.queryParam.total = res.total
            },

            async searchActivity() {
                this.queryParam.pageNum = 1
                this.queryParam.current = 1
                const { data: res } = await this.$http.post("getActivityList", {
                    title: this.queryParam.title,
                    pagesize: this.queryParam.pageSize,
                    pagenum: this.queryParam.pageNum
                })
                if (res.status != 200) return this.$message.error(res.message);
                this.Activitylist = res.data
                this.queryParam.total = res.total
            },

            deleteActivity(id) {
                this.$confirm({
                    title: '提示：请再次确认',
                    content: '确定要删除该Activity吗？一旦删除，无法恢复',
                    onOk: async () => {
                        const { data: res } = await this.$http.delete(`delActivity/${id}`)
                        if (res.status != 200) return this.$message.error(res.message)
                        this.$message.success('删除成功')
                        this.getActivityList()
                    },
                    onCancel: () => {
                        this.$message.info('已取消删除')
                    },
                })
            },

            addActivityOk() {
                this.$refs.addActivityRef.validate(
                    async (valid) => {
                        if (!valid) return this.$message.error('参数不符合要求，请重新输入')
                        const { data: res } = await this.$http.post(`addActivity`,
                            {
                                title: this.activityInfo.title,
                                password: this.activityInfo.password,
                                role: this.activityInfo.role
                            })
                        if (res.status != 200) return this.$message.error(res.message)
                        this.$message.success('添加Activity成功')
                        this.addActivityVisible = false
                        this.$refs.addActivityRef.resetFields()
                        this.getActivityList()
                    })

            },

            addActivityCancel() {
                this.$refs.addActivityRef.resetFields()
                this.addActivityVisible = false
            },

            adminChange(value) {
                // console.log(value)
                this.activityInfo.role = value
                // this.activityInfo.role = parseInt(value)
            },

            async editActivity(id) {
                this.editActivityVisible = true
                const { data: res } = await this.$http.post(`getActivity`,
                    { "id": id })
                this.activityInfo = res.data
                this.activityInfo.id = id
            },

            editActivityOk() {
                this.$refs.editActivityRef.validate(async (valid) => {
                    if (!valid) return this.$message.error('参数不符合要求，请重新输入')
                    const { data: res } = await this.$http.put(`editActivity/${this.activityInfo.id}`,
                        this.activityInfo
                    )
                    
                })
            },

            editActivityCancel() {
                this.$refs.editActivityRef.resetFields()
                this.editActivityVisible = false
                this.$message.info("编辑取消")
            },

            handleTableChange(pagination, filters, sorter) {
                var pager = { ...this.pagination }
                pager.current = pagination.current
                pager.pageSize = pagination.pageSize
                this.queryParam.pageSize = pagination.pageSize
                this.queryParam.pageNum = pagination.current

                if (pagination.pageSize !== this.pagination.pageSize) {
                    this.queryParam.pageNum = 1
                    pager.current = 1
                }

                this.pagination = pager
                this.getActivityList()
            },

        },

    }
</script>

<style scoped>
    .actionSlot {
        display: flex;
        justify-content: center;
    }

    .title {
        text-align: center;
    }
</style>