import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";
import Admin from "../views/Admin.vue";
import AddActivity from "../components/activity/AddActivity.vue";
import ActivityList from "../components/activity/ActivityList.vue";
import AddEnroll from "../components/activity/AddEnroll.vue";
import EnrollQRCode from "../components/activity/EnrollQRCode.vue";

// import  ArticleList  from '../components/article/ArticleList.vue'
import CategoryList from "../components/category/CategoryList.vue";
// import  AddUser  from '../components/user/AddUser.vue'
// import  UserList  from '../components/user/UserList.vue'

Vue.use(VueRouter);

const routes = [
  //  {
  //   path:'/',
  //   name:'index',
  //   component: Admin
  //  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },

  {
    path: "/",
    name: "admin",
    component: Admin,
    children: [
      // {
      //   path: '/AddActivity',
      //   component: AddActivity,
      //   meta: {
      //     title: '新增Activity'
      //   }
      // },
      {
        path: "/ActivityList",
        component: ActivityList,
        meta: {
          title: "Activity列表",
        },
      },

      {
        path: "/AddEnroll/:id",
        component: AddEnroll,
        meta: {
          title: "AddEnroll",
        },
        props: true,
      },
      {
        path: "/EnrollQRCode/:uuid",
        component: EnrollQRCode,
        meta: {
          title: "EnrollQRCode",
        },
        props: true,
      },
      // {
      //   path: '/AddActivity/:id',
      //   component: AddActivity,
      //   meta: {
      //     title: '编辑Activity'
      //   },
      //   props:true
      // },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  const token = window.sessionStorage.getItem("token");
  if (to.path == "/login") return next();
  if (!token) {
    next("/login");
  } else {
    next();
  }
});

export default router;
