<template>
    <div class="dashboard-container">
        <h3 class="title">{{ id ? '编辑Activity' : '新增Activity' }}</h3>
        <el-card>

            <el-form :model="activityInfo" :rules="activityInfoRules" ref="activityInfoRef" :hideRequiredMark="true"
                label-width="100px" size="mini">
                <el-row>
                    <el-col :span="15">
                        <el-form-item label="题目" style="width: 700px; " prop="username">
                            <el-input v-model="activityInfo.name"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="9">
                        <el-form-item label="分类" prop="cid">
                            <el-select v-model="activityInfo.cid" placeholder="请选择分类" style="width: 200px;"
                                @change="catChange">
                                <el-option v-for="item in Catlist" :key="item.ID" :value="item.ID" :label="item.name">
                                    {{item.name}}
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col :span="15">
                        <el-form-item label="开始时间" style="width: 350px;" prop="username">
                            <el-date-picker v-model="activityInfo.begin_time" type="datetime" placeholder="选择开始时间">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="9">
                        <el-form-item label="结束时间" style="width: 350px;" prop="username">
                            <el-date-picker v-model="activityInfo.end_time" type="datetime" placeholder="选择开始时间">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>


                <el-form-item label="海报" prop="image">
                    <el-upload name="file" :headers="headers" :action="upUrl" :on-success="upChange">
                        <el-button>
                            <i class="el-icon-upload"></i>上传
                        </el-button>
                        <template v-if="id">
                            <img :src="activityInfo.image" style="width: 120px; height: 100px; margin-left: 15px" alt>
                        </template>
                    </el-upload>
                </el-form-item>

                <el-form-item label="内容" prop="role">
                    <!-- <el-input v-model="lectureInfo.content" ></el-input> -->
                    <template>
                        <mavon-editor ref=md :subfield="subfield" :code_style="code_style" :ishljs="true"
                            @imgAdd="uploadImg" @imgDel="delImg" v-model="activityInfo.content"></mavon-editor>
                    </template>
                </el-form-item>

                <el-form-item>
                    <el-button type="danger" style="margin: 15px;" @click="activityOk(activityInfo.id)">
                        {{
                        activityInfo.id ? '更新' : '提交'
                        }}
                    </el-button>
                    <el-button type="primary" style="margin: 15px;" @click="addCancel">
                        取消
                    </el-button>
                </el-form-item>
            </el-form>
        </el-card>
    </div>
</template>

<script>
    import { Url } from '../../plugin/http'
    export default {
        props: ['id'],
        data() {
            return {
                subfield: true,
                code_style: 'atom-one-dark',
                // lectureInfo:{
                //     id:0,
                //     title:'',
                //     cid:undefined,
                //     desc:'',
                //     content:'',
                //     img:'',
                // },
                type: ["活动", "报名"],

                activityInfo: {
                    id: 0,
                    name: '',
                    cid: undefined,
                    begin_time: undefined,
                    end_time: undefined,
                    content: '',
                    addr: '',
                    image: '',
                    num: 0,
                    status: '审核通过'
                },
                Catlist: [],
                upUrl: this.$http.defaults.baseURL + '/upload',
                headers: {},
                formData: {},
                object_dir: '',
                filelist: [],
                activityInfoRules: {
                    name: [{ required: true, message: "输入Activity标题", trigger: "change" }],
                    cid: [{ required: true, message: '请选择Activity分类', trigger: 'change' }],
                    num: [
                        { required: true, message: '请输入Activity数量', trigger: 'change' }
                    ],
                    // img: [{ required: true, message: '请选择Activity缩略图', trigger: 'change' }],
                    content: [{ required: true, message: '请输入Activity内容', trigger: 'change' }],

                }
            }
        },
        mounted() {
            console.log(this.upUrl)
            this.getCatList()
            this.headers = { Authorization: `Beare ${window.sessionStorage.getItem('token')}` }
            if (this.id) {
                this.getactivityInfo(this.id)

            }
            
        },
        methods: {

            delImg(pos, $file) {
                console.log($file)
            },

            // https://github.com/hinesboy/mavonEditor/blob/master/doc/cn/upload-images.md
            uploadImg(pos, $file) {
                console.log(this.upUrl)
                // 第一步.将图片上传到服务器.
                let formdata = new FormData();
                formdata.append('image', $file);
                // uploadPostImg(formdata).then(data => {
                //   // 第二步.将返回的url替换到文本原位置![...](0) -> ![...](url)
                //   // $vm.$img2Url 详情见本页末尾
                //   console.log(JSON.stringify(data));
                //   this.$refs.md.$img2Url(pos, data.data);
                // });
            },

            async getactivityInfo(id) {
                const { data: res } = await this.$http.post("getActivity", {
                    id: parseInt(id)
                })
                if (res.status != 200) return this.$message.error(res.message);
                this.activityInfo = res.data
                this.activityInfo.id = res.data.ID
            },

            async getCatList() {
                const { data: res } = await this.$http.post("getCategoryAll")
                if (res.status != 200) return this.$message.error(res.message);
                this.Catlist = res.data
            },

            catChange(cid) {
                this.activityInfo.cid = cid
            },

            upChange(info) {
                // console.log(info)
                // if(info.status !=200){

                // }
                if (info.status == 200) {
                    this.$message.success("上传成功！")
                    this.activityInfo.image = info.data
                } else if (info.file.status == "error") {
                    this.$message.success("上传失败！")
                }
            },

            async submitUpload(info) {
                const formData = new FormData()
                formData.append("file", info.file);
                formData.append("filename", info.file.name)
                // formData.append("file",  file);
                // formData.append("object_dir", this.object_dir)
                const { data: res } = await this.$http.post("upload",
                    formData
                    // {
                    //     formData: formData,
                    , { headers: { "Content-Type": 'multiplecture/form-data; ' } }
                    // }
                )
                if (res.status != 200) return this.$message.error(res.message);
                // this.Catlist =res.data
            },

            lectureOk(id) {
                this.$refs.lectureInfoRef.validate(
                    async (valid) => {
                        if (!valid) return this.$message.error('参数验证未通过，请按要求录入JZZ内容')
                        if (id == 0) {
                            const { data: res } = await this.$http.post("addActivity", this.activityInfo)
                            if (res.status != 200) return this.$message.error(res.message);
                            this.$message.success("添加Activity成功")
                        } else {
                            const { data: res } = await this.$http.put(`editActivity/${id}`, this.activityInfo)
                            // if(res.status != 200) 
                            if (res.status != 200) return this.$message.error(res.message)
                            this.editActivityVisible = false
                            this.$message.success('更新Activity信息成功')
                            this.$refs.activityInfoRef.resetFields()
                            this.getactivityInfo(this.id)
                            //                     this.editLectureVisible = false
                            // this.$message.success('更新文章信息成功')
                            // this.$refs.editLectureRef.resetFields()
                            // this.getLectureList()
                            // {return this.$message.error(res.message);}
                            // else{
                            // this.getlectureInfo(this.id)
                            // this.$message.success("更新JZZ成功")
                            // }

                        }
                    }

                )
            },

            addCancel() {
                this.$refs.activityInfoRef.resetFields()
            }
        }
    }



</script>

<style scoped>
    .title {
        text-align: center;

    }

    .el-form-item {
        margin-bottom: 3px;

    }
</style>