<template>
    <div class="dashboard-container">

        <h3 class="title">报名</h3>
        <el-card>

            <el-form :model="enrollInfo" :rules="enrollInfoRules" ref="enrollInfoRef" :hideRequiredMark="true"
                label-width="100px" size="mini">
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="活动名称:" style="width: 300px; " prop="name_">
                            <!-- <el-input v-model="enrollInfo.name"></el-input> -->
                            {{activityInfo.name}}
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="开始时间:" style="width: 300px; " prop="begin_time">
                            <!-- <el-input v-model="enrollInfo.name"></el-input> -->
                            {{activityInfo.begin_time | dateFormat }}
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="结束时间:" style="width: 300px; "  prop="end_time">
                            <!-- <el-input v-model="enrollInfo.name"></el-input> -->
                            {{activityInfo.end_time | dateFormat}}
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="活动地点:" style="width: 300px; "  prop="addr">
                            <!-- <el-input v-model="enrollInfo.name"></el-input> -->
                            {{activityInfo.addr}}
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="姓名" style="width: 300px; " prop="name">
                            <el-input v-model="enrollInfo.name"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="性别" prop="sex">
                            <el-select v-model="enrollInfo.sex" placeholder="请选择性别" style="width: 200px;"
                                @change="sexChange">
                                <el-option v-for="item in sexlist" :key="item" :value="item" :label="item">
                                    {{item}}
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="专业" style="width: 300px;" prop="major">
                            <el-input v-model="enrollInfo.major"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="9">
                        <el-form-item label="联系方式" style="width: 300px;" prop="tel">
                             <el-input v-model="enrollInfo.tel"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="校区" prop="campus">
                            <el-select v-model="enrollInfo.campus" placeholder="请选择校区" style="width: 200px;"
                                @change="campusChange">
                                <el-option v-for="item in campuslist" :key="item" :value="item" :label="item">
                                    {{item}}
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col :span="24">
                        <el-form-item label="参加方式" prop="type">
                            <el-select v-model="enrollInfo.type" placeholder="请选择线上或线下" style="width: 200px;"
                                @change="typeChange">
                                <el-option v-for="item in typelist" :key="item" :value="item" :label="item">
                                    {{item}}
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>



                <el-form-item>
                    <el-button type="danger" style="margin: 15px;" @click="enrollOk()">
                       提交
                    </el-button>
                    <el-button type="primary" style="margin: 15px;" @click="addCancel">
                        取消
                    </el-button>
                </el-form-item>
            </el-form>
        </el-card>
    </div>
</template>

<script>
    import { Url } from '../../plugin/http'
    export default {
        props: ['id'],
        data() {
            return {
                subfield: true,
                code_style: 'atom-one-dark',
                activityID:undefined,
                // enrollInfo:{
                //     id:0,
                //     title:'',
                //     cid:undefined,
                //     desc:'',
                //     content:'',
                //     img:'',
                // },
                typelist: ["线上", "线下"],
                sexlist: ["男", "女"],
                campuslist: ["玉泉校区","紫金港校区","华家池校区","之江校区","西溪校区","海宁校区","校外"],

                activityInfo:{
                    id: 0,
                    name: '',
                    cid: undefined,
                    begin_time: undefined,
                    end_time: undefined,
                    content: '',
                    uuid:'',
                    num: 0,
                    image: '',

                },

                enrollInfo: {
                    id: 0,
                    name: '',
                    sex: undefined,
                    major: undefined,
                    tel: undefined,
                    campus: '',
                    aid: undefined,
                    status: '审核通过',
                    type: '',

                },
                Catlist: [],
                upUrl: this.$http.defaults.baseURL + '/upload',
                headers: {},
                formData: {},
                object_dir: '',
                filelist: [],
                enrollInfoRules: {
                    name: [{ required: true, message: "请输入姓名", trigger: "change" }],
                    sex: [{ required: true, message: '请选择性别', trigger: 'change' }],
                    major: [{ required: true, message: '请输入专业', trigger: 'change' }],
                    // addr: [{ required: true, message: '请选择校区', trigger: 'change' }],
                    tel: [{ required: true, message: '请输入联系方式', trigger: 'change' }],
                    campus: [{ required: true, message: '请选择校区', trigger: 'change' }],
                    type: [{ required: true, message: '请选择参加方式', trigger: 'change' }],

                }
            }
        },
        watch: {
        id: function(newVal){
            this.activityID = newVal; //newVal即是chartData
        }
        },
        mounted() {
            // console.log(this.upUrl)
            // this.getCatList()
            // this.headers = { Authorization: `Beare ${window.sessionStorage.getItem('token')}` }
            if (this.id) {

                
                this.enrollInfo.aid = parseInt(this.id)
                // console.log("this.enrollInfo.aid = this.id")
                // console.log(this.id)
                // console.log( this.enrollInfo.aid)
                // console.log("this.enrollInfo.aid = this.id")
                this.getActivityInfo(this.id)

            }
        },
        methods: {

            async getActivityInfo(id) {
                const { data: res } = await this.$http.post("getActivity", {
                    id: parseInt(id)
                })
                if (res.status != 200) return this.$message.error(res.message);
                this.activityInfo = res.data
                this.activityInfo.id = res.data.ID
            },

            sexChange(sex) {
                this.enrollInfo.sex = sex
            },
            campusChange(campus) {
                this.enrollInfo.campus= campus
            },
            typeChange(type) {
                this.enrollInfo.type= type
            },

            upChange(info) {
                // console.log(info)
                // if(info.status !=200){

                // }
                if (info.status == 200) {
                    this.$message.success("上传成功！")
                    this.enrollInfo.image = info.data
                } else if (info.file.status == "error") {
                    this.$message.success("上传失败！")
                }
            },

            enrollOk() {
                // console.log("this.activityID")
                // console.log(this.activityID)
                // console.log(this.id)
                // console.log("this.id")
                this.$refs.enrollInfoRef.validate(
                    async (valid) => {
                        if (!valid) return this.$message.error('参数验证未通过，请按要求录入报名信息')
                 
                //         console.log("this.enrollInfo.aid = this.id")
                // console.log(this.enrollInfo)
                // console.log( this.enrollInfo.aid)
                // console.log("this.enrollInfo.aid = this.id")
                        const { data: res } = await this.$http.post("addEnroll", this.enrollInfo)
                        if (res.status != 200) return this.$message.error(res.message);
                        this.$message.success("报名成功,请扫码加入微信群")
                        this.$router.push({ path: `/EnrollQRCode/${this.activityInfo.uuid}` })
                        
                    }

                )
            },

            addCancel() {
                this.$refs.enrollInfoRef.resetFields()
            }
        }
    }



</script>

<style scoped>
    .title {
        text-align: center;

    }

    .el-form-item {
        margin-bottom: 3px;

    }
</style>