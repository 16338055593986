<template>
    <div class="dashboard-container">

         <div class="image-container">

          <el-image :src="this.image" class="image-resize" />
          </div>
    </div>
</template>

<script>
    import { Url } from '../../plugin/http'
    export default {
        props: ['uuid'],
        data() {
            return {
                image: undefined,
                activityID:undefined,
            
            }
        },
            watch: {
      uuid: function(newVal){
        this.activityID = newVal; //newVal即是chartData
      }
    },
        mounted() {
            // console.log("this.uuid")
            // console.log(this.uuid)
            if (this.uuid) {
                this.getActivityImage(this.uuid)
            }
        },
        methods: {

            async getActivityImage(uuid) {
                const { data: res } = await this.$http.post("getActivityImage", {
                    uuid: uuid
                })
                if (res.status != 200) return this.$message.error(res.message);
                this.image = res.data.image

            },

            delImg(pos, $file) {
                console.log($file)
            },

            // https://github.com/hinesboy/mavonEditor/blob/master/doc/cn/upload-images.md
            uploadImg(pos, $file) {
                console.log(this.upUrl)
                // 第一步.将图片上传到服务器.
                let formdata = new FormData();
                formdata.append('image', $file);
                // uploadPostImg(formdata).then(data => {
                //   // 第二步.将返回的url替换到文本原位置![...](0) -> ![...](url)
                //   // $vm.$img2Url 详情见本页末尾
                //   console.log(JSON.stringify(data));
                //   this.$refs.md.$img2Url(pos, data.data);
                // });
            },

            async getEnrollInfo(id) {
                const { data: res } = await this.$http.post("getEnroll", {
                    id: parseInt(id)
                })
                if (res.status != 200) return this.$message.error(res.message);
                this.enrollInfo = res.data
                this.enrollInfo.id = res.data.ID
            },

            async getCatList() {
                const { data: res } = await this.$http.post("getCategoryAll")
                if (res.status != 200) return this.$message.error(res.message);
                this.Catlist = res.data
            },

            sexChange(sex) {
                this.enrollInfo.sex = sex
            },
            campusChange(campus) {
                this.enrollInfo.campus= campus
            },

            upChange(info) {
                // console.log(info)
                // if(info.status !=200){

                // }
                if (info.status == 200) {
                    this.$message.success("上传成功！")
                    this.enrollInfo.image = info.data
                } else if (info.file.status == "error") {
                    this.$message.success("上传失败！")
                }
            },

            async submitUpload(info) {
                const formData = new FormData()
                formData.append("file", info.file);
                formData.append("filename", info.file.name)
                // formData.append("file",  file);
                // formData.append("object_dir", this.object_dir)
                const { data: res } = await this.$http.post("upload",
                    formData
                    // {
                    //     formData: formData,
                    , { headers: { "Content-Type": 'multiplecture/form-data; ' } }
                    // }
                )
                if (res.status != 200) return this.$message.error(res.message);
                // this.Catlist =res.data
            },

            enrollOk(uuid) {
                this.$refs.enrollInfoRef.validate(
                    async (valid) => {
                        if (!valid) return this.$message.error('参数验证未通过，请按要求录入JZZ内容')
                        if (id == 0) {
                            const { data: res } = await this.$http.post("addEnroll", this.enrollInfo)
                            if (res.status != 200) return this.$message.error(res.message);
                            this.$message.success("报名成功,请扫码加入微信群")
                            this.$router.push({ path: `/EnrollQRCode/${uuid}` })
                        } else {
                            const { data: res } = await this.$http.put(`editEnroll/${id}`, this.enrollInfo)
                            // if(res.status != 200) 
                            if (res.status != 200) return this.$message.error(res.message)
                            this.editenrollVisible = false
                            this.$message.success('更新Enroll信息成功')
                            this.$refs.enrollInfoRef.resetFields()
                            this.getenrollInfo(this.id)
                            //                     this.editenrollVisible = false
                            // this.$message.success('更新文章信息成功')
                            // this.$refs.editenrollRef.resetFields()
                            // this.getenrollList()
                            // {return this.$message.error(res.message);}
                            // else{
                            // this.getenrollInfo(this.id)
                            // this.$message.success("更新JZZ成功")
                            // }

                        }
                    }

                )
            },

            addCancel() {
                this.$refs.enrollInfoRef.resetFields()
            }
        }
    }



</script>

<style scoped>
    .title {
        text-align: center;

    }

    .image-container {
  /* max-width: 200px;
  max-height: 200px; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-resize {
  max-width: 500px;
  max-height: 700px;
}

    .el-form-item {
        margin-bottom: 3px;

    }
</style>